import React from "react"
import Container from "../../components/container"
import CustomHeader from "../../components/customHeader"
import SideBarView from "../../components/SideBarView"
import Seo from "../../components/Seo"
import { Typography, Grid } from "@mui/material"
import { CodeBlockView } from "../../components/HelperComponents"

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const OracleTimestampConversions = () => {
  return (
    <Container>
      <CustomHeader title={`Golang Timestamp Conversions`}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography variant={`p`}>
            Oracle is one of the leading relational database management systems in the world. It is widely used in
            various industries, including finance, healthcare, and government agencies. Oracle has various built-in
            functions that allow users to convert dates and times from one format to another. In this blog, we will
            explore the different types of timestamp conversions in Oracle with examples and explanations.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Types of Timestamp Conversion in Oracle
          </Typography>
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting a TIMESTAMP value to a string
          </Typography>
          <Typography variant={`p`}>
            Oracle provides the TO_CHAR() function to convert a TIMESTAMP value to a string. The TO_CHAR() function
            takes two arguments: the first argument is the TIMESTAMP value that you want to convert, and the second
            argument is the format in which you want the TIMESTAMP value to be displayed.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Here is an example:
          </Typography>
          {programmingRoutineView({
            language: `sql`,
            code: `SELECT TO_CHAR(SYSTIMESTAMP, 'DD-MON-YYYY HH:MI:SS.FF') FROM dual;`,
            description: `This query will convert the SYSTIMESTAMP value to a string in the format of 'DD-MON-YYYY HH:MI:SS.FF', for example: '12-MAR-2023 02:30:45.123456789 AM'.`
          })}

          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting a string to a TIMESTAMP value
          </Typography>
          <Typography variant={`p`}>
            Oracle provides the TO_TIMESTAMP() function to convert a string to a TIMESTAMP value. The TO_TIMESTAMP()
            function takes two arguments: the first argument is the string value that you want to convert, and the
            second argument is the format in which the string value is represented.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Here is an example:
          </Typography>
          {programmingRoutineView({
            language: `sql`,
            code: `SELECT TO_TIMESTAMP('12-MAR-2023 02:30:45.123456789 AM', 'DD-MON-YYYY HH:MI:SS.FF AM') FROM dual;`,
            description: `This query will convert the string value '12-MAR-2023 02:30:45.123456789 AM' to a TIMESTAMP value.`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Extracting a specific portion of a TIMESTAMP value
          </Typography>
          <Typography variant={`p`}>
            Oracle provides various functions to extract specific portions of a TIMESTAMP value, such as the year,
            month, day, hour, minute, and second.
          </Typography>
          <br/><br/>
          <Typography variant={`p`}>
            Here are some examples:
          </Typography>
          {programmingRoutineView({
            language: `sql`,
            code: `SELECT EXTRACT(YEAR FROM SYSTIMESTAMP) FROM dual;
SELECT EXTRACT(MONTH FROM SYSTIMESTAMP) FROM dual;
SELECT EXTRACT(DAY FROM SYSTIMESTAMP) FROM dual;
SELECT EXTRACT(HOUR FROM SYSTIMESTAMP) FROM dual;
SELECT EXTRACT(MINUTE FROM SYSTIMESTAMP) FROM dual;
SELECT EXTRACT(SECOND FROM SYSTIMESTAMP) FROM dual;`,
            description: `The first query will extract the year portion from the SYSTIMESTAMP value. Similarly, the other queries will extract the month, day, hour, minute, and second portions of the SYSTIMESTAMP value, respectively.`
          })}
          <Typography variant={`h2`} sx={{ marginTop: `30px` }}>
            Converting a TIMESTAMP value to a date
          </Typography>
          <Typography variant={`p`}>
            Oracle provides the CAST() function to convert a TIMESTAMP value to a date. Here is an example:
          </Typography>
          {programmingRoutineView({
            language: `sql`,
            code: `SELECT CAST(SYSTIMESTAMP AS DATE) FROM dual;`,
            description: `This query will convert the SYSTIMESTAMP value to a date.`
          })}
          <br/><br/>
          <Typography variant={`p`}>
            In conclusion, Oracle provides various built-in functions to convert dates and times from one format to
            another. The TO_CHAR() function is used to convert a TIMESTAMP value to a string, the TO_TIMESTAMP()
            function is used to convert a string to a TIMESTAMP value, and the EXTRACT() function is used to extract
            specific portions of a TIMESTAMP value. Additionally, the CAST() function is used to convert a TIMESTAMP
            value to a date. These functions can be very helpful in managing and manipulating date and time values in
            Oracle.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default OracleTimestampConversions

export const Head = () => {
  return (
    <Seo
      title="Mastering Timestamp Conversions in Oracle: A Comprehensive Guide"
      canonicalUrl={"https://www.epochconverter.io/blogs/python-timestamp-conversions"}
    />
  )
}
